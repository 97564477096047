import { ISettingsParam } from '@wix/tpa-settings';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { ISettingsParams, ISettingsParamsDefinitions } from './settingsParams';
import { IStyleParamsBoolean, IStyleParamsVariants } from './stylesParams';
import { SubtitleSourceOptions } from '../../types/types';

export const getFromSettingsParams = ({
  settingsParams,
  tpaData,
  isMobile,
  key,
}: {
  settingsParams: Partial<ISettingsParamsDefinitions>;
  tpaData: any;
  isMobile: boolean;
  key: keyof ISettingsParams;
}) => {
  return (
    (isMobile
      ? settingsParams[key]?.inheritDesktop ?? true
        ? tpaData[`${key}▶︎m`] ?? tpaData[key]
        : tpaData[`${key}▶︎m`]
      : tpaData[key]) ??
    settingsParams[key]?.getDefaultValue({
      isMobile,
      getSettingParamValue: (
        param: ISettingsParam,
        options?: { isMobile?: boolean; isRTL?: boolean; isEditorX?: boolean },
      ) =>
        getFromSettingsParams({
          isMobile,
          settingsParams,
          tpaData,
          key: param.key as keyof ISettingsParams,
        }),
    } as any)
  );
};

export const generateCustomCssVars = (
  args: Parameters<CustomCssVarsFn>[0],
  settingsParams: Partial<ISettingsParamsDefinitions>,
) => {
  const { styleParams, tpaData, isMobile } = args;

  const getBooleanStyleParam = (
    key: keyof IStyleParamsBoolean,
  ): boolean | undefined => {
    return styleParams.booleans[key];
  };

  const getNumberStyleParam = (
    key: keyof IStyleParamsVariants,
  ): number | undefined => {
    return styleParams.numbers[key];
  };

  const displayVariants = (
    key: keyof IStyleParamsVariants,
    totalNumberOfVariants: number,
  ) => {
    const variant =
      getNumberStyleParam(key) ??
      (getFromSettingsParams({
        settingsParams,
        tpaData,
        isMobile,
        key,
      }) as number);

    const variants: { [key: string]: string } = {};

    for (let i = 0; i < totalNumberOfVariants; i++) {
      variants[`${key}_${i}`] = i === variant ? 'block' : 'unset';
    }

    return variants;
  };

  const numberOfKeysInEnum = (enumType: any): number =>
    Object.keys(enumType).filter((key) => isNaN(Number(key))).length;

  const displayStyle = (key: keyof IStyleParamsBoolean) => {
    return getBooleanStyleParam(key) ??
      getFromSettingsParams({
        settingsParams,
        tpaData,
        isMobile,
        key,
      })
      ? {
          [key + 'Hidden']: 'unset',
          [key + 'DisplayBlock']: 'block',
          [key + 'DisplayFlex']: 'flex',
        }
      : {
          [key + 'Hidden']: 'none',
          [key + 'DisplayBlock']: 'unset',
          [key + 'DisplayFlex']: 'unset',
        };
  };

  return {
    ...displayStyle('headerSubtitleVisibility'),
    ...displayStyle('headerTitleVisibility'),
    ...displayStyle('headerLocationFilterVisibility'),
    ...displayStyle('headerStaffFilterVisibility'),
    ...displayStyle('headerServiceFilterVisibility'),
    ...displayStyle('headerVisibility'),
    ...displayStyle('headerTimezoneSelectionVisibility'),
    ...displayVariants(
      'headerSubtitleSource',
      numberOfKeysInEnum(SubtitleSourceOptions),
    ),
  };
};
